<template>    
    <div class="banner">
                <Particles
                id="tsparticles"
                :particlesInit="particlesInit"
                :particlesLoaded="particlesLoaded"
                :options="{
                    background: {
                        color: {
                            value: 'transparent'
                        }
                    },
                    fpsLimit: 120,
                    interactivity: {
                        events: {
                            onClick: {
                                enable: false,
                                mode: 'push'
                            },
                            onHover: {
                                enable: false,
                                mode: 'repulse'
                            },
                            resize: true
                        },
                        modes: {
                            bubble: {
                                distance: 400,
                                duration: 2,
                                opacity: 0.8,
                                size: 40
                            },
                            push: {
                                quantity: 4
                            },
                            repulse: {
                                distance: 200,
                                duration: 0.4
                            }
                        }
                    },
                    particles: {
                        color: {
                            value: ['#3687c8', '#b51f83']                     
                        },
                        links: {
                            color: '#ffffff',
                            distance: 150,
                            enable: false,
                            opacity: 0.5,
                            width: 1
                        },
                        collisions: {
                            enable: true
                        },
                        move: {
                            direction: 'none',
                            enable: true,
                            outModes: {
                                default: 'bounce'
                            },
                            random: false,
                            speed: 0.3,
                            straight: false
                        },
                        number: {
                            density: {
                                enable: true,
                                area: 1200
                            },
                            value: 80
                        },
                        opacity: {
                            value: 0.5
                        },
                        shape: {
                            type: 'circle'
                        },
                        size: {
                            value: 2,
                            random: true,
                            animation: {
                                enable: true,
                                speed: 10,
                                minimumValue: 0.1,
                                sync: false
                            },
                            move: {
                                enable: true,
                            },
                        },
                        animation: {
                            enable: true,
                            speed: 20,
                            minimumValue: 0.1,
                            sync: false
                        },
                    },
                    detectRetina: true
                }"
        />
        <div class="box-container title-container">
            <gradient-bar :reverse="true" height="250" ></gradient-bar>
            <h1>NFT</h1>            
        </div>
    </div>     
    <section class="nft" id="def">
        <div class="box-container">
            <h2>Qu’est ce qu’un NFT ? </h2>
            <h3>"<u>N</u>on <u>F</u>ungible <u>T</u>okens" en Anglais - "Jetons Non Fongibles" en Français</h3>
        </div>
        <div class="box-border">
            <p class="subtitle">Les NFT permettent de certifier l'authenticité et le caractère unique d'une création numérique</p>
            <div class="box-icon-container">
                <div class="box-icon-item" v-for="(item, idx) in icons" :key="idx">
                    <img :src="item.img" alt="icon" />
                    <p v-html="item.text"></p>
                </div>
            </div>
        </div>
        <div class="box-container">
            <p>Chez <strong>Born2Mint</strong>, nous sommes convaincus que la technologie des <strong>NFT</strong> est une Révolution pour tous les <strong>concepteurs</strong>, <strong>créateurs</strong>, <strong>artistes</strong> et <strong>talents</strong> ! En effet, ces derniers trouvent dans cet outil la possibilité de donner un caractère unique à un élément numérique par essence reproductible à l'infini.</p>
            <p>Il s'agit d'un élément cryptographique et virtuel <strong>« gravé » dans la blockchain</strong> avec des codes d'identification et des métadonnées (nom du créateur, date, type, etc.) qui les distinguent les uns des autres. Un <strong>NFT</strong> est alors <strong>unique</strong>, comme peut l’être une œuvre d’art d’un Grand Maître. C'est la blockchain qui permet de certifier cette <strong>unicité et ainsi d'en garantir l'authenticité à son propriétaire</strong> et, potentiellement, à son futur acquéreur.</p>
            <p>Les NFT sont considérés comme des objets de collection ayant une valeur intrinsèque en raison de leur importance culturelle. Ces NFT sont donc considérés par les investisseurs comme des placements extrêmement lucratifs surtout quand ils sont portés par des Grandes Marques ou des Célébrités.</p>
            <p>Le marché des jetons non fongibles (NFT), auparavant une niche pour les joueurs et les crypto-enthousiastes, a atteint le grand public avec des artistes majeurs tels que Beeple, Grimes et Trevor Jones qui utilisent les NFT pour leurs œuvres d’art.</p>
            <p>Enfin, Christie’s et Sotheby’s ont eux aussi franchis le pas et ont commencé à vendre des NFT aux enchères.</p>
            <p class="nft-highlight">En conclusion,  les NFT permettent la protection<br />et la monétisation d’œuvres digitales</p>
        </div>
    </section>
    <section class="advantage" id="advantage">
        <div class="box-container">
            <h2>QUELS AVANTAGES POUR <br />UNE MARQUE OU UN TALENT ?</h2>
            <div class="row">
                <div class="col-33">
                    <img src="./../assets/nft-cube.png" alt="nft cube" class="nft-cube" />
                </div>
                <div class="col-66">
                    <ul>
                        <li><strong>Générer des revenus supplémentaires</strong></li>
                        <li>Rendre son image de marque encore plus <strong>Premium</strong> et <strong>Innovante</strong></li>
                        <li>Devenir la <strong>1<sup>ère</sup> marque</strong> ou le <strong>1<sup>er</sup> talent</strong> dans son domaine à proposer ce type d’œuvres digitales à collectionner</li>
                        <li>Faire le <strong>buzz</strong> au niveau national ou international</li>
                        <li>Permettre aux détenteurs de ces NFT d’accéder à des <strong>événements exclusifs</strong></li>
                        <li>Proposer du <strong>contenu unique</strong> non piratable et non falsifiable (car protégé par les technologies de la blockchain)</li>
                        <li>Être <strong>rémunéré</strong> tout au long de la vie de l’œuvre digitale et sur toutes les transactions futures</li>
                        <li>Offrir de nouveaux objets de collection à ses fans et followers ainsi que, pourquoi pas, une nouvelle génération de goodies</li>
                        <li>Créer un <strong>nouveau type</strong> de lien avec sa communauté</li>
                        <li>Susciter <strong>l’intérêt</strong> des collectionneurs</li>

                    </ul>
                </div>
            </div>
        </div>
    </section>
    <section class="collection" id="criteria">
        <div class="box-container">
            <h2>LES CRITères de réussite<br />d’une collection nft</h2>
            <div class="collection-list">
                <ul>
                    <li>Crédibilité & Légitimité du porteur du projet/de l’équipe</li>
                    <li>Histoire de la Collection et/ou Emotion générée par celle-ci</li>
                    <li>Utilité des NFT
                        <ul class="sub-list">
                            <li>Accès à un événement exclusif, à une rencontre privilégiée ou à une visite privée</li>
                            <li>Déblocage de privilèges particuliers ou de remises commerciales</li>
                            <li>Etc.</li>
                        </ul>
                    </li>
                    <li>Sérieux des outils de promotion</li>
                    <li>Qualité de la Communauté créée autour de la Collection NFT</li>
                    <li>Taille de la Collection & Rareté des NFT</li>
                </ul>
            </div>
        </div>
    </section>
    <section class="follow" id="follow">
        <div class="border-box-gradient">
            <h2>un accompagnement de a à z <br />dans cette aventure</h2>
            <p class="follow-focus">Nous nous occupons de tout !</p>
            <!-- circle container -->

            <div class="circle-container">
                <div class="circle-item circle1">
                    <div class="circle-inner">
                        <div class="circle-number">1</div>
                        <p>création</p>
                    </div>
                </div>
                <div class="circle-item circle2">
                    <div class="circle-inner">
                        <div class="circle-number">2</div>
                        <p>publication<br />sur la blockchain</p>
                    </div>
                </div>
                <div class="circle-item circle3">
                    <div class="circle-inner">
                        <div class="circle-number">3</div>
                        <p>promotion</p>
                    </div>
                </div>
                <div class="cicle-sep-1">></div>
                <div class="cicle-sep-2">></div>
                <div class="cicle-sep-1 circle-resp">˅</div>
                <div class="cicle-sep-2 circle-resp">˅</div>
            </div>

            <div class="follow-container">
                <div class="follow-number number1">1</div>
                <!-- <h3>Focus sur le 1<sup>er</sup> volet d’accompagnement</h3> -->
                <p><strong>Conception & Réalisation d’une collection d’œuvres digitales NFT en accord avec votre univers :</strong><br />Illustrations, Dessins, Visuels 2D, Visuels 3D, Photos et/ou Vidéos</p>
                <ul>
                    <li>Soit nous mettons en forme et harmonisons des éléments fournis par vos soins dont vous avez les droits (ou pour lesquels vous pouvez facilement les obtenir)</li>
                    <li>Soit nous créons pour vous des œuvres digitales en lien avec votre univers<br />
                        <span>Nous avons les équipes en interne pour réaliser des dessins ou des illustrations 2D, des visuels 3D, de courtes animations 3D, de la prise de photos ou de vidéos.</span>
                    </li>
                </ul>
            </div>

            <div class="follow-sep"></div>

            <div class="follow-container">
                <div class="follow-number number2">2</div>
                <!-- <h3>Focus sur le 2<sup>ème</sup> volet d’accompagnement</h3> -->
                <p><strong>Publication sur la Blockchain & Mise en place des NFT</strong><br /></p>
                <ul>
                    <li>
                        Sélection de la plateforme de vente et de la Blockchain correspondante<br />
                        <span>Selon la taille de la collection et le type de NFT, certaines plateformes peuvent être plus pertinentes que d’autres.</span>
                    </li>
                    <li>
                        Création & Configuration d’un portefeuille dédié pour payer les frais en cryptomonnaies et recevoir les recettes des ventes
                        <span>L’accès à cet écosystème nécessite quelques outils techniques qu’il faut installer puis configurer. Il est nécessaire aussi de disposer de cryptomonnaies pour payer les frais de transactions.</span>
                    </li>
                    <li>
                        Publication technique sur la Blockchain & Sécurisation de la Collection NFT
                        <span>L’opération de « minting » consiste à transformer l’œuvre digitale en NFT protégée sur la Blockchain.</span>
                    </li>
                    <li>
                        Détermination du mode de vente à prix fixe ou aux enchères
                        <span>Selon la stratégie déterminée, nous pouvons choisir entre ces 2 types de vente. Parfois, selon la rareté de certains NFT au sein même de la collection, un modèle	hybride peut être envisagé avec, par exemple, une pièce de la collection vendue aux enchères car elle a une portée encore plus particulière et symbolique que les autres œuvres.</span>
                    </li>
                </ul>
            </div>

            <div class="follow-sep"></div>

            <div class="follow-container">
                <div class="follow-number number3">3</div>
                <!-- <h3>Focus sur le 3<sup>ème</sup> volet d’accompagnement</h3> -->
                <p><strong>Organisation, Création & Suivi de la campagne de promotion</strong><br /></p>
                <ul>
                    <li>
                        Ecriture de « l’Histoire » du projet et de la Collection NFT<br />
                        <span>Par expérience, les Collections de NFT légitimes et portées par une personne, une équipe, une marque ou une institution en mesure de raconter une « Histoire » rencontrent un bien meilleur succès que les autres.</span>
                    </li>
                    <li>
                        Conception & Création du logo de la Collection
                        <span>	Pour la communication et la promotion sur les réseaux sociaux, il est recommandé d’associer un logo à une collection afin de créer une identité propre et intrinsèque à la collection NFT.</span>
                    </li>
                    <li>
                        Création d’un site Internet éphémère dédié d’une page
                        <span>Les plateformes pour vendre les NFT ne permettent pas vraiment d’expliquer le pourquoi de la collection, l’histoire de celle-ci, etc. Le site Internet répond à ces besoins pour que les acheteurs puissent comprendre d’où la collection vient, pourquoi elle a été créée, pourquoi  les NFT qui la compose ont de la valeur et pourquoi ces derniers pourraient en prendre encore plus dans les années à venir.</span>
                    </li>
                    <li>
                        Rédaction d’un communiqué de presse
                        <span>	Nous diffuserons aussi sur Internet et sur les réseaux sociaux un communiqué de presse afin	de relayer l’information auprès des journalistes et des médias.</span>
                    </li>
                    <li>
                        Promotion de la Collection NFT sur les réseaux sociaux
                        <span>Les réseaux sociaux et notamment Twitter sont absolument inévitables dans la promotion d’une collection NFT et il est impératif de prévoir une vraie campagne de publications/tweets pour promouvoir la collection.<br /> Cette stratégie de communication peut s’accompagner de « giveaways » (sous forme de mini-concours ou de loteries) qui consistent à offrir un peu d’argent ou des NFT gratuits à certains  followers qui like ou retweet les posts de promotion.</span>
                    </li>
                </ul>
            </div>
            
            <div class="box-contact">
                <router-link :to="{name: 'Contact'}" class="btn">contactez-nous !</router-link>
            </div>
        </div>
    </section>
</template>

<script>
import GradientBar from "./../components/GradientBar.vue";

export default {
    name : "NFT",
    components: {
        GradientBar
    },
    metaInfo: {
        title: 'Votre Expert NFT pour vous épauler dans vos projets NFT et Web 3.0',
        meta: [
            { name: 'description', content: 'Born2Mint vous accompagne sur 360° dans la conception, la création, le minting, la commercialisation et la promotion de vos Collections de NFT' },
        ]
    },
    data() {
        return {
            icons : [
                {
                    img : require("@/assets/icons-nft/icon-01.png"),
                    text : "photo"
                },
                {
                    img : require("@/assets/icons-nft/icon-02.png"),
                    text : "video"
                },
                {
                    img : require("@/assets/icons-nft/icon-03.png"),
                    text : "son"
                },
                {
                    img : require("@/assets/icons-nft/icon-04.png"),
                    text : "illustration<br />dessin"
                },
                {
                    img : require("@/assets/icons-nft/icon-06.png"),
                    text : "image 3d"
                },
                {
                    img : require("@/assets/icons-nft/icon-07.png"),
                    text : "clip 3d"
                },
                {
                    img : require("@/assets/icons-nft/icon-08.png"),
                    text : "fichier 3d"
                },
            ],            
        }
    },

    

}
</script>

<style scoped lang="scss">
@import "@/scss/_variables.scss";



.title-container {
  display: flex;
  justify-content: center;
  align-items: center;
    width: 100%;
  margin-left:-50px;
}

h1 {
  color:#fff;
  font-size:60px;
  font-weight: 600;
  text-align: right;
  text-transform: uppercase;
  margin:0;
  letter-spacing: 2px;
}

.banner-image {
    position:absolute;
    bottom:0;
    transition:0.5s;
    display: none;
}

/* NFT SECTION */
section.nft {
    padding:80px 0;
    background:$secondGradient;
    background-size: 400% 400%;
	animation: gradient 15s ease infinite;
    position: relative;
    z-index: 1;
}

.nft h3 {
    color:#fff;
    font-size:18px;
    // text-transform: uppercase;
    margin:0;
    text-align: center;
    font-weight:300;
    font-style: italic;
}

.nft .col-50 {
    width:50%;
    margin:0;
}

.nft .col-50 .nft-col-first {
    margin-right:10%;
}

.nft .col-50 .nft-col-last {
    margin-left:10%;
}

p.nft-highlight {
    text-align: center;
    font-size: 30px;
    text-transform: uppercase;
    line-height:1.2em;
    font-weight:400;
}

.box-border {
    width:1320px;
    max-width: calc(90% - 80px);
    margin:40px auto;
    border:1px solid #fff;
    border-radius:10px;
    padding:20px 40px;
}

.box-border p.subtitle {
    text-align:center;
    font-size:20px;
    text-transform: uppercase;
    font-weight:600;
}

.box-icon-container {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
}

.box-icon-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin:20px;
    width:120px;
}

.box-icon-item p {
    text-align: center;
    font-weight: 600;
    font-size:20px;
    text-transform: uppercase;
    line-height: 1.2em;
}


/* Advanateg section */

section.advantage {
    padding:80px 0;
}

section.advantage .row {
    margin-top:80px;
    align-items: center;
}


.nft-cube {
    position:relative;
    z-index:1;
    max-width: 90%;
    animation: bounce 4s infinite;
}

@keyframes bounce {
    0%  {
        margin-top:-20px;
    }

    50% {
        margin-top: 20px;
    }

    100% {
        margin-top: -20px;
    }
}


/* collection section */
section.collection {
    padding:80px 0;
    background:$secondGradient;
    background-size: 400% 400%;
	animation: gradient 15s ease infinite;
    background-repeat: no-repeat;
        position: relative;
    z-index: 1;
}

section.collection h2 {
    margin-bottom:80px;
}

.collection-list {
    width:60%;
    margin:0 auto;
}

.collection-list li {
    font-weight:600;
}

.collection-list .sub-list {
    margin-left:40px;
}

.collection-list .sub-list li {
    font-weight: 300;
    margin:5px 0;
}


/* focus section */

section.follow {
    padding:80px 0 20px 0;
}

.border-box-gradient {
    padding:80px 40px;
    position: relative;
    display: flex;
    flex-direction: column;
    border-radius:10px;
    background:#000;
    width:1320px;
    max-width: calc(90% - 80px);
    margin:40px auto;
}

.border-box-gradient:before {
    content: "";
   position: absolute;
   top: 0;
   right: 0;
   bottom: 0;
   left: 0;
   z-index: -1;
   margin: -1px;
   border-radius: inherit;
   background: $mainGradient;
}

.follow-focus {
    text-align: center;
    font-size:20px;
    font-weight:600;
}

.follow-sep {
    margin:20px auto;
    width:60%;
    height:1px;
    background:$mainGradient;
}

.follow-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin:20px auto;
}

.follow-container ul {
    width:800px;
    max-width: 100%;
}

.follow-container ul li {
    font-weight:600;
}

.follow-container ul li span {
    padding-left:20px;
    font-weight: 300;
    display: block;
}

.follow-container p {
    text-align: center;
    margin-bottom:20px;
    margin-top:20px;
    font-size:20px;
    text-transform: uppercase;
}

.follow-container h3 {
    text-align: center;
    font-size:30px;
    font-weight:600;
    background: $mainGradient;
    background-clip: text;
    -webkit-text-fill-color: transparent;
    text-transform: uppercase;
    margin:0;
}

.follow-container h3 sup {
    font-size:18px;
}

.follow-number {
    width:80px;
    min-width: 80px;
    height:80px;
    border-radius:50%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size:60px;
    font-weight:700;
    color:#fff;
    margin-bottom:20px;
    border:4px solid #fff;
}

// .number1 {
//     border:4px solid #881763;
// }

// .number2 {
//     border:4px solid #6f438c;
// }

// .number3 {
//     border:4px solid #3587c9;
// }

.box-contact {
    margin-top:40px;
    display: flex;
    justify-content: center;
}

/* circles */

.circle-container {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    margin:80px auto;
    width:1080px;
}

.circle-item {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius:50%;
    height:350px;
    width:350px;
    min-width: 350px; 
    margin:0 5px;  
    padding:2px;
}

.circle1 {
    background: linear-gradient(90deg, #9a1a70, #9d3c9a);
}

.circle1 .circle-inner {
    animation: focus1 8s infinite;
}

.circle2 {
    background: linear-gradient(90deg, #9d3c9a, #6367b6);
}

.circle2 .circle-inner {
    animation: focus2 8s infinite;
}

.circle3 {
    background: linear-gradient(90deg, #6367b6, #3f84d6);
}

.circle3 .circle-inner {
    animation: focus3 8s infinite ;
}


.circle-inner {
    background:#000;
    border-radius:50%;
    height:350px;
    width:350px;
    min-width: 350px; 
    display: flex;
    flex-direction: column;
    align-items: center;
}

.circle-inner p {
    text-align: center;
    color:#fff;
    text-transform: uppercase;
    font-size:25px;
    margin:0;
    line-height: 1.1em;
    font-weight:600;
}

.circle-number {
    margin-top:60px;
    margin-bottom:25px;
    width:70px;
    min-width: 70px;
    height:70px;
    display: flex;
    justify-content: center;
    align-items: center;
    color:#fff;
    font-size:40px;
    font-weight:700;
    border-radius:50%;
    // background:#000;
    border:2px solid #fff;
}

// .circle1 .circle-number {
//     border:4px solid #881763;
// }

// .circle2 .circle-number {
//     border:4px solid #6f438c;
// }

// .circle3 .circle-number {
//     border:4px solid #3587c9;
// }

.cicle-sep-1 {
    width:50px;
    min-width: 50px;
    height:50px;
    background:#9d3c9a;
    border-radius:50%;
    display: flex;
    justify-content: center;
    align-items: center;
    color:#000;
    font-size:30px;
    font-weight:700;
    position:absolute;
    top:calc(50% - 22px);
    left:333px;
    animation: translate1 2s infinite;
    box-shadow: 0 0 1px 1px rgba(0,0,0,0.5) ;
}

.cicle-sep-2 {
    width:50px;
    min-width: 50px;
    height:50px;
    background:#6367b6;
    border-radius:50%;
    display: flex;
    justify-content: center;
    align-items: center;
    color:#000;
    font-size:30px;
    font-weight:700;
    position:absolute;
    top:calc(50% - 22px);
    left:698px;
    animation: translate1 2s infinite;
    box-shadow: 0 0 1px 1px rgba(0,0,0,0.5) ;
}

.circle-resp {
    display: none;
}

@keyframes translate1 {
 0% {
     transform: scale(1);
 }   

 50% {
     transform: scale(1.2);
 }
 100% {
     transform: scale(1);
 } 
}

@keyframes focus1 {
    0% {
        background: #000;
    }
    25% {
        background : transparent;
    }
    50% {
        background : #000;
    }
    75% {
       background : #000;
    }
    100% {
        background:#000;
    }
}

@keyframes focus2 {
    0% {
        background: #000;
    }
    25% {
        background : #000;
    }
    50% {
        background : transparent;
    }
    75% {
       background : #000;
    }
    100% {
        background:#000;
    }
}

@keyframes focus3 {
    0% {
        background: #000;
    }
    25% {
        background : #000;
    }
    50% {
        background : #000;
    }
    75% {
       background : transparent;
    }
    100% {
        background:#000;
    }
}

@keyframes gradient {
	0% {
		background-position: 0% 50%;
	}
	50% {
		background-position: 100% 50%;
	}
	100% {
		background-position: 0% 50%;
	}
}


@media screen and (max-width:768px) { 
    h1 {
        font-size:40px;
    }

    .title-container {
        margin: 0 auto;
    } 

    .advantage .col-33 {
        height:400px;
        width:100%;
    }

    .col-66 {
        width:100%;
    }

    .col-66 ul, .collection-list ul {
        margin-left:30px;
    }

    .collection-list {
        width:90%;
    }

    .border-box-gradient {
        padding: 40px 10px;
        max-width: calc(96% - 20px);
    }

    .follow-container {
        max-width: 90%;
    }

    .follow-container ul {
        margin-left:10px;
    }

    .circle-container {
        flex-direction: column;
        max-width: 100%;
       
    }

    .circle-item, .circle-inner {
        width:300px;
        min-width: 300px;
        height:300px;
         margin:5px 0;
    }

    .circle-inner p {
        font-size:20px;
    }

    .circle1 {
        background: linear-gradient( #9a1a70, #9d3c9a);
    }

    .circle2 {
        background: linear-gradient( #9d3c9a, #6367b6);
    }

    .circle3 {
        background: linear-gradient( #6367b6, #3f84d6);
    }

    .cicle-sep-1.circle-resp {
        display: flex;
        top:285px;
        left:calc(50% - 25px);
    }

    .cicle-sep-1, .cicle-sep-2 {
        display: none;
    }

    .cicle-sep-2.circle-resp {
        display: flex;
        top:605px;
        left:calc(50% - 25px);
    }

    .box-icon-item {
        width:100px;
        margin:10px;
    }

    .box-icon-item p {
        font-size:18px;
    }
}


</style>